<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <md-card class="md-card-global-sales flex-1">
        <md-card-header class="md-card-header-icon md-card-header-blue">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">
            Info Page Editor
          </h4>
        </md-card-header>

        <md-card-content>
          <form-uploader
            :max-size="5*1024"
            class="text-center"
            @error="fireError"
            @input="onFileUploaded"
          >
            <b>Main Image</b>
            <img
              :src="form.image_url || default_img"
              class="imgPreview"
            >
            <small>Click Image to change</small>
          </form-uploader>

          <div class="text-center mt-4">
            <b>Body</b>
          </div>

          <trumbowyg
            v-model="form.body"
            :config="config"
          />

          <div class="d-flex">
            <FormText
              :value="form.map_url"
              icon="place"
              label="Map URL"
              class="flex-1"
              @input="onChangeMapUrl"
            />
            <md-button
              class="md-just-icon md-simple md-sm md-info mt-2"
              @click="showMapDetails"
            >
              <md-icon>info</md-icon>
            </md-button>
          </div>

          <md-button
            class="md-primary md-sm md-block"
            @click="saveData"
          >
            SAVE
          </md-button>
        </md-card-content>
      </md-card>
    </div>
    <div class="md-layout-item md-size-30">
      <md-card class="md-card-global-sales">
        <md-card-header class="md-card-header-icon md-card-header-blue">
          <div class="card-icon">
            <md-icon>visibility</md-icon>
          </div>
          <h4 class="title">
            About Us Page Preview
          </h4>
        </md-card-header>

        <md-card-content>
          <img
            v-if="form.image_url"
            :src="form.image_url"
            class="imgPreview"
          >

          <div class="max-w-450">
            <div v-html="form.body" />

            <iframe
              v-if="form.map_url"
              :src="form.map_url"
              width="100%"
              height="300"
              style="border:0;"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            />
          </div>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
import { FormUploader, FormText } from '@/components/Inputs';

export default {
  components: {
    FormUploader,
    FormText,
  },
  data: () => ({
    config: {
      btns: [
        ['viewHTML'],
        ['undo', 'redo'], // Only supported in Blink browsers
        ['formatting'],
        ['strong', 'em', 'del'],
        ['superscript', 'subscript'],
        ['link'],
        ['insertImage'],
        ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
        ['unorderedList', 'orderedList'],
        ['horizontalRule'],
        ['removeformat'],
      ],
      semantic: {
        div: 'div', // Editor does nothing on div tags now
      },
    },
    form: {
      image: null,
      image_url: null,
      body: null,
      map_url: null,
    },
    loading: false,
    default_img: '/img/about-us-default.jpg',
  }),
  mounted() {
    this.getData();
  },
  methods: {
    onFileUploaded(file) {
      this.form.image = file;
      this.form.image_url = URL.createObjectURL(file);
    },
    showMapDetails() {
      this.fireModal({
        title: 'Map URL Details',
        html: `
          <div class="text-left">
            To obtain the Map URL follow these steps: <br>
            <ul>
              <li>
                Go To Google Maps: 
                <a href="https://www.google.com/maps" target="_blank">https://www.google.com/maps</a>
              </li>
              <li>
                Find your location and click on it.
              </li>
              <li>
                Click on Share button & Select "embed a map"
              </li>
              <li>
                Copy & paste content into Map Url input.
              </li>
            </ul>
          </div>
        `,
      });
    },
    getData() {
      this.loading = true;

      this.request('app/about_us', 'get', null, ({ data }) => {
        if (data.image_url) this.form.image_url = data.image_url;
        if (data.body) this.form.body = data.body;
        if (data.map_url) this.form.map_url = data.map_url;
      }, () => {
        this.loading = false;
      });
    },
    saveData() {
      this.loading = true;

      const fd = new FormData();
      if (this.form.image) fd.append('image', this.form.image);
      fd.append('body', this.form.body);
      fd.append('map_url', this.form.map_url);

      this.request('app/about_us', 'put', fd, (data) => {
        this.fireSuccess(data);
      }, () => {
        this.loading = false;
      });
    },
    /**
     * Script to extract MapUrl from google iframe
     */
    onChangeMapUrl(string) {
      if (!string) {
        this.form.map_url = null;
        return;
      }
      if (string.slice(0, 4) === 'http') return;

      const element = document.createElement('div');
      element.innerHTML = string;
      this.form.map_url = element.firstChild.src;
    },
  },
};
</script>

<style scoped>
.max-w-450 {
  max-width: 450px;
  margin: auto;
}
.imgPreview {
  max-width: 450px;
  margin: .25em auto .5em;
  display: block;
}
</style>
